import LangWrapper from "./components/LangWrapper";
import { useAppCache } from "./hooks/useAppCache";
import { useAppScrollTop } from "./hooks/useAppScrollTop";
import { useAppDispatch, useAppSelector } from "./store";
import { selectIsAuthorized } from "./store/auth";
import { useEffect } from "react";
import { getKashaPayments, getPaymentsParameters } from "./store/payments";
import { ThemeWrapper } from "./components/ThemeWrapper";
import { getMode, getTheme } from "./store/theme";
import { Outlet } from "react-router-dom";
import { useFavicon } from "./hooks/useFavicon";
import { useCheckIpCountry } from "./hooks/useCheckIpCountry";
import { SENTRY_INIT_CONFIG } from './sentry-init.config';
import * as Sentry from '@sentry/react';
import { IS_DEV_MODE } from "config";
import { DEV_API_URLS } from "api/api";

Sentry.init(SENTRY_INIT_CONFIG);

const App = () => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(selectIsAuthorized);

    useAppCache();
    useCheckIpCountry(isAuth);

    useEffect(() => {
        dispatch(getPaymentsParameters());

        if (isAuth) {
            dispatch(getKashaPayments());
            dispatch(getMode());
        }
    }, [isAuth]);

    useEffect(() => {
        const url = IS_DEV_MODE ? DEV_API_URLS.CRM : window?.crmApiUrl;
        dispatch(getTheme(url));
    }, [window?.crmApiUrl])

    useAppScrollTop();
    useFavicon();

    return (
        <LangWrapper>
            <ThemeWrapper>
                <Outlet/>
            </ThemeWrapper>
        </LangWrapper>
    )
}

export default App;