export const paymentsMethodDeposit = [
    {
        value: 'Crypto',
        label: 'Cryptocurrency',
    },
    {
        value: 'credit_card_ca',
        label: 'Credit Card',
    },
    {
        value: 'bank_card_ca',
        label: 'Bank Card',
    },
];

export const DepositAmountOptions = [250, 500, 1000];

export const PAYMENTS = {
    kasha: 'kasha',
    mandato: 'mandato.global',
};

export const cryptoWallets = {
    btc: 'Bitcoin',
    ltc: 'Litecoin',
    eth: 'Ethereum',
    dash: 'Dash',
    doge: 'Doge',
    usdt: 'Tether',
    usdc: 'USDC',
    trx: 'Tron',
};

export const withdrawWalletOptions = [ 
    { value: 'btc', label: 'Bitcoin' },
    { value: 'ltc', label: 'Litecoin' },
    { value: 'eth', label: 'Ethereum' },
    { value: 'dash', label: 'Dash' },
    { value: 'doge', label: 'Doge' },
    { value: 'usdt', label: 'Tether' },
];

export const DEPOSIT_TYPE = {
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
};