import { ReactNode } from "react";
import { UKIcon } from "../icons/flags/UK";
import { GermanyIcon } from "../icons/flags/Germany";
import { FranceIcon } from "../icons/flags/France";
import { PolandIcon } from "../icons/flags/Poland";
import { LitIcon } from "../icons/flags/LitIcon";
import { RussiaIcon } from "../icons/flags/Russian";
import {
    ITS_WITH_CZECH,
    ITS_WITH_ITALIAN,
    ITS_WITH_JAPANESE,
    ITS_WITH_KOREAN,
    ITS_WITH_PORTUGUESE,
    ITS_WITH_ROMANIAN,
    ITS_WITH_TURKISH,
} from "../../config";
import ItFlag from "../../assets/img/it.png";
import TurFlag from "../../assets/img/tur.png";
import CzFlag from "../../assets/img/cz.png";
import RoFlag from "../../assets/img/ro.png";
import JaFlag from "../../assets/img/ja.png";
import PtFlag from "../../assets/img/pt.png";
import ESFlag from "../../assets/img/es.png";
import ArFlag from "../../assets/img/ar.png";
import KoFlag from "../../assets/img/ko.png";
import { IndexedObjectType } from "../../types/helpers";

type LangOptions = IndexedObjectType<{ icon: ReactNode, label: string, enabled: boolean }>

export const langOptions: LangOptions = {
    en: {
        icon: <UKIcon />,
        label: 'English',
        enabled: true
    },
    de: {
        icon: <GermanyIcon />,
        label: 'Deutsch',
        enabled: true
    },
    es: {
        icon: <img width={16} height={16} src={ESFlag} alt=''/>,
        label: 'Español',
        enabled: true
    },
    fr: {
        icon: <FranceIcon />,
        label: 'Français',
        enabled: true
    },
    lt: {
        icon: <LitIcon />,
        label: 'Lietuvių',
        enabled: true
    },
    pl: {
        icon: <PolandIcon />,
        label: 'Polski',
        enabled: true
    },
    ar: {
        icon: <img width={16} height={16} src={ArFlag} alt=''/>,
        label: 'ﺔﻴﺑﺮﻌﻟا ﺔﻐﻠﻟا',
        enabled: true
    },
    ru: {
        icon: <RussiaIcon />,
        label: 'Русский',
        enabled: true
    },
    it: {
        icon: <img width={16} height={16} src={ItFlag} alt=''/>,
        label: 'Italiano',
        enabled: ITS_WITH_ITALIAN
    },
    tr: {
        icon: <img width={16} height={16} src={TurFlag} alt=''/>,
        label: 'Türkçe',
        enabled: ITS_WITH_TURKISH
    },
    cz: {
        icon: <img width={16} height={16} src={CzFlag} alt=''/>,
        label: 'Čeština',
        enabled: ITS_WITH_CZECH
    },
    ja: {
        icon: <img width={16} height={16} src={JaFlag} alt=''/>,
        label: '日本語',
        enabled: ITS_WITH_JAPANESE
    },
    pt: {
        icon: <img width={16} height={16} src={PtFlag} alt=''/>,
        label: 'Português',
        enabled: ITS_WITH_PORTUGUESE
    },
    ro: {
        icon: <img width={16} height={16} src={RoFlag} alt=''/>,
        label: 'Romanian',
        enabled: ITS_WITH_ROMANIAN
    },
    ko: {
        icon: <img width={16} height={16} src={KoFlag} alt=''/>,
        label: 'Korean',
        enabled: ITS_WITH_KOREAN
    }
};