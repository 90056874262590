import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { REQUIRED_FIELD_VALIDATION } from "../../utils/validators";
import { DEPOSIT_TYPE, DepositAmountOptions, withdrawWalletOptions } from "../../constants/finance";
import { Button } from "../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../store";
import { addToast } from "../../store/toast";
import { addDeposit } from "../../api/deposit";
import { useForm, useWatch } from "react-hook-form";
import { AmountInput } from "../../components/Form/AmountInput/AmountInput";
import { useGetTradingAccounts } from "../../hooks/useGetTradingAccounts";
// import { useWithdrawAvailability } from "../../hooks/useWithdrawAvailability";
import { formatAccountSelectOptions } from "../../components/Form/_helpers/formatAccountSelectOptions";
import { AccountSelectOption } from "../../components/Form/_helpers/AccountSelectOption";
import { FormInput } from "../../components/Form/FormInput/FormInput";
import { getResponseErrorMessage } from "../../utils/getResponseErrorMessage";
import { selectPaymentCreditCardWithdraw, selectPeaymentBankCardWithdraw } from "../../store/payments";
import { compact } from 'lodash';
import './WithdrawModal.scss';

type SubmitDataTypes = {
    amount: string,
    amount_type: string,
    trading_account_id: string,
    type: string,
    method: string,
    crypto_type: string,
    wallet_address: string,
    card_number: string
}

export const WITHDRAW_MODAL_KEY = 'WITHDRAW_MODAL_KEY';

export const WithdrawModal = ({closeModal}) => {
    const dispatch = useAppDispatch();
    const {
        control,
        handleSubmit,
        setValue,
        clearErrors,
        trigger,
        formState: {errors}
    } = useForm({
        defaultValues: {
            amount: '',
            crypto_type: {value: 'btc', label: 'Bitcoin'},
            wallet: '',
            card: '',
            payment_method: undefined,
            fromWithdraw: undefined
        }
    });
    const intl = useIntl();
    const { tradingAccounts } = useGetTradingAccounts();
    const [walletStep, setWalletStep] = useState('start');
    const [isLoading, setLoadStatus] = useState(false);

    const creditCardEnabled = useAppSelector(selectPaymentCreditCardWithdraw);
    const bankCardEnabled = useAppSelector(selectPeaymentBankCardWithdraw);

    const paymentMethodsWithdraw = compact([
        { value: 'Crypto', label: 'Cryptocurrency' },
        { value: 'Wire', label: 'Wire transfer' },
        creditCardEnabled && { label: 'Credit card', value: 'credit_card_ca' },
        bankCardEnabled && { label: 'Bank card', value: 'bank_card_ca' }
    ])
    
    const {amount, payment_method, fromWithdraw} = useWatch({control});
    const isWireMethod = payment_method?.value === paymentMethodsWithdraw[1].value;
    const isCardMethod = payment_method?.value === 'credit_card_ca' || payment_method?.value === 'bank_card_ca'

    // TODO: Replace the amount validation with availableWithdraw?.available_balance_amount
    // const availableWithdraw = useWithdrawAvailability(fromWithdraw);

    const onSubmit = (values) => {
        setLoadStatus(true);
        const paymentMethod = values?.payment_method?.value;
        const postData: Partial<SubmitDataTypes> = {
            amount: values?.amount,
            amount_type: values?.fromWithdraw?.data?.amount_type,
            trading_account_id: values?.fromWithdraw?.data?.trading_account,
            type: DEPOSIT_TYPE.WITHDRAW,
            method: values?.payment_method?.value,
        };

        if (paymentMethod === 'Crypto') {
            if (!values?.crypto_type || !values?.wallet) {
                dispatch(addToast({
                    type: 'error',
                    message: intl.formatMessage({ id: 'form.error.fillRequired' }),
                    timeout: 3000
                }));
                setLoadStatus(false);
                return;
            }
            postData.crypto_type = values?.crypto_type.value.toUpperCase();
            postData.wallet_address = values?.wallet;
        } else if (paymentMethod === 'credit_card_ca' || paymentMethod === 'bank_card_ca') {
            if (!values?.card) {
                dispatch(addToast({
                    type: 'error',
                    message: intl.formatMessage({ id: 'form.error.fillRequired' }),
                    timeout: 3000
                }));
                setLoadStatus(false);
                return;
            }
            postData.card_number = values.card;
        }

        addDeposit(postData)
            .then(() => {
                dispatch(addToast({
                    type: 'success',
                    title: intl.formatMessage({ id: 'status.success.title', defaultMessage: 'Success' }),
                    message: intl.formatMessage({id: 'transaction.withdraw.alert.created'}),
                    timeout: 5000,
                }));
                closeModal();
            })
            .catch((err) => {
                dispatch(addToast({
                    type: 'error',
                    message: getResponseErrorMessage(err),
                    timeout: 5000,
                }));
            })
            .finally(() => {
                setLoadStatus(false);
            });
    };

    return (
        <div className="withdraw-modal">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={walletStep !== 'start' ? 'hide' : ''}>
                    <div className='modal-template-content-subtitle'>
                        <FormattedMessage
                            id="page.finance.withdraw.popup.descr.text"
                            defaultMessage="Request withdrawal from one of you trading accounts by selecting an account from the list, choosing a payment account and defining the desired withdrawal amount."
                        />
                    </div>
                    <SelectInput
                        id="payment_method_deposit_modal"
                        name="payment_method"
                        label={
                            <FormattedMessage
                                id="page.finance.widthdraw.popup.paymentMethod"
                                defaultMessage="Payment Method"
                            />
                        }
                        control={control}
                        rules={REQUIRED_FIELD_VALIDATION}
                        options={paymentMethodsWithdraw}
                    />
                    <SelectInput
                        id="fromWithdraw_withdraw_modal"
                        name="fromWithdraw"
                        label={
                            <FormattedMessage
                                id="page.finance.withdraw.popup.field.fromTradingAccount.title"
                                defaultMessage="Choose trading account"
                            />
                        }
                        control={control}
                        rules={REQUIRED_FIELD_VALIDATION}
                        options={formatAccountSelectOptions(tradingAccounts)}
                        formatOptionLabel={AccountSelectOption}
                        maxMenuHeight={180}
                    />
                    <AmountInput
                        id="amount_withdraw_modal"
                        name="amount"
                        label={
                            <FormattedMessage
                                id="page.finance.withdraw.popup.field.amount.title"
                                defaultMessage="Amount"
                            />
                        }
                        currency={fromWithdraw?.data?.amount_type}
                        disabled={!fromWithdraw}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            // validate: (value) => value <= availableWithdraw?.available_balance_amount ||
                            validate: (value) => value <= Number(fromWithdraw?.data?.balance?.replace(',', '')) ||
                                <FormattedMessage
                                    id="page.finance.withdraw.popup.validation"
                                    defaultMessage="Not enough funds"
                                />
                        }}
                        onChange={() => {
                            trigger("amount");
                        }}
                        setValue={setValue}
                        control={control}
                        buttons={DepositAmountOptions}
                        // details={
                        //     availableWithdraw &&
                        //     `${intl.formatMessage({
                        //         id: 'withdraw.available',
                        //         defaultMessage: 'Available',
                        //     })}: ${availableWithdraw.available_balance_amount}`
                        // }
                    />
                    <div className="controls-warning _m-y-15 _fz-12 _p-15">
                        <FormattedMessage
                            id="page.finance.withdraw.popup.note.text"
                            defaultMessage="Note: Withdrawal request will be reviewed."
                        />
                    </div>
                    <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
                        <Button buttonType="outline" onClick={closeModal} className="_m-r-10">
                            <FormattedMessage id="page.finance.withdraw.popup.btn.cancel" defaultMessage="Cancel"/>
                        </Button>
                        <Button
                            tag="button"
                            type={isWireMethod ? 'submit' : 'button'}
                            showSpinner={isLoading}
                            isDisabled={isLoading || !!Object.keys(errors).length || !payment_method || !fromWithdraw || !amount}
                            onClick={() => {
                                if (isWireMethod) {
                                    handleSubmit(onSubmit);
                                } else if (payment_method?.value === 'Crypto') {
                                    setWalletStep('crypto_select');
                                } else if (isCardMethod) {
                                    setWalletStep('card_select');
                                }
                            }}
                        >
                            {isWireMethod ? <FormattedMessage id='page.finance.withdraw.popup.btn.checkoutNow' defaultMessage='Checkout Now'/> :
                            isCardMethod ? <FormattedMessage id='page.finance.withdraw.popup.btn.toCard' defaultMessage='To Card'/> :
                            <FormattedMessage id='page.finance.withdraw.popup.btn.toWallet' defaultMessage='To Wallet'/>}
                        </Button>
                    </div>
                </div>

                {walletStep !== 'start' ? (
                    <div>
                        <div className='modal-template-content-subtitle'>
                            {walletStep === 'crypto_select' ? (
                                <FormattedMessage
                                    id="page.finance.withdraw.popup.deposit.title"
                                    defaultMessage="Enter Wallet number & currency"
                                />
                            ) : null}
                            {/*TODO: translations*/}
                            {walletStep === 'card_select' ? 'Enter your card' : null}
                        </div>
                        <FormInput
                            name={walletStep === 'card_select' ? 'card' : 'wallet'}
                            type="text"
                            placeholder={intl.formatMessage({
                                id: walletStep === 'card_select' ?
                                    'page.finance.withdraw.popup.deposit.card_number' :
                                    "page.finance.withdraw.popup.deposit.wallet_number",
                                defaultMessage: walletStep === 'credit_select' ? 'Card Number' : 'Wallet Number'
                            })}
                            label={intl.formatMessage({
                                id: walletStep === 'card_select'
                                        ? 'page.finance.withdraw.popup.deposit.card_number'
                                        : 'page.finance.withdraw.popup.deposit.wallet_number',
                                defaultMessage: walletStep === 'credit_select' ? 'Card Number' : 'Wallet Number',
                            })}
                            rules={REQUIRED_FIELD_VALIDATION}
                            control={control}
                        />
                        <div className="_flex _flex-a-c">
                            <div className="_flex _flex-a-c">
                                {amount} {fromWithdraw?.data?.amount_type ? fromWithdraw?.data?.amount_type : 'USD'}
                                {walletStep === 'crypto_select' ? <i className="fi fi-rr-right _m-x-10"/> : null}
                            </div>
                            {walletStep === 'crypto_select' ? (
                                <SelectInput
                                    className="_flex-gr-1"
                                    name="crypto_type"
                                    id="crypto_type"
                                    label={intl.formatMessage({
                                        id: "page.finance.withdraw.popup.deposit.currency",
                                        defaultMessage: "Currency"
                                    })}
                                    control={control}
                                    rules={REQUIRED_FIELD_VALIDATION}
                                    options={withdrawWalletOptions}
                                />
                            ) : null}
                        </div>
                        <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
                            <Button
                                buttonType="outline"
                                onClick={() => {
                                    clearErrors();
                                    setWalletStep('start');
                                }}
                                className="_m-r-10">
                                <FormattedMessage id="page.finance.withdraw.popup.deposit.back" defaultMessage="Back"/>
                            </Button>
                            <Button
                                tag='button'
                                type='submit'
                                showSpinner={isLoading}
                                disabled={isLoading}
                            >
                                <FormattedMessage
                                    id="page.finance.withdraw.popup.btn.checkoutNow"
                                    defaultMessage="Checkout now"
                                />
                            </Button>
                        </div>
                    </div>
                ) : null}
            </form>
        </div>
    );
}