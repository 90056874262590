import { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../store";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { useForm, useWatch } from "react-hook-form";
import {
    DEPOSIT_TYPE,
    DepositAmountOptions,
    PAYMENTS,
    paymentsMethodDeposit
} from "../../constants/finance";
import { AmountInput } from "../../components/Form/AmountInput/AmountInput";
import {
    selectPaymentBankCardEnabled,
    selectPaymentCreditCardEnabled,
    selectPaymentIsEnabled,
    selectPaymentsCurrency,
    selectPaymentsCurrencyKasha,
    selectPaymentsGateways
} from "../../store/payments";
import { useNavigate } from "react-router-dom";
import { addDeposit } from "../../api/deposit";
import { addToast } from "../../store/toast";
import { AccountSelectOption } from "../../components/Form/_helpers/AccountSelectOption";
import { formatAccountSelectOptions } from "../../components/Form/_helpers/formatAccountSelectOptions";
import { REQUIRED_FIELD_VALIDATION } from "../../utils/validators";
import { ITradingAcc } from "../../types/payments";
import { useGetTradingAccounts } from "../../hooks/useGetTradingAccounts";
import { getResponseErrorMessage } from "../../utils/getResponseErrorMessage";
import { openModal, selectModalProps } from "../../store/modal";
import { WALLET_MODAL_KEY } from "../WalletModal/WalletModal";
import { IS_DWS_DOMAIN } from "../../config";

export const DEPOSIT_MODAL_KEY = 'DEPOSIT_MODAL_KEY';

export const DepositModal = ({closeModal}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const intl = useIntl();

    const paymentData = useAppSelector(selectModalProps);
    const paymentsCurrency = useAppSelector(selectPaymentsCurrency);
    const paymentsCurrencyKasha = useAppSelector(selectPaymentsCurrencyKasha);
    const ENABLE_PAYMENT = useAppSelector(selectPaymentIsEnabled);
    const paymentsGateways = useAppSelector(selectPaymentsGateways);
    const ENABLE_CREDIT_CARD = useAppSelector(selectPaymentCreditCardEnabled);
    const ENABLE_BANK_CARD = useAppSelector(selectPaymentBankCardEnabled);
    const { tradingAccounts } = useGetTradingAccounts();

    const {control, handleSubmit, setValue} = useForm({
        defaultValues: paymentData ? {
            amount: paymentData.amount,
            payment_method: null,
            payment_currency: paymentsCurrency.find(item => item.value === paymentData.amount_type),
            toDeposit: formatAccountSelectOptions(tradingAccounts).find(item => item.value === paymentData?.trading_account_id),
            gateway: null,
        } : undefined
    });

    const {amount, payment_currency, payment_method, toDeposit, gateway} = useWatch({control});
    const isCreditCardPayment = payment_method?.value === 'credit_card_ca' && ENABLE_PAYMENT;
    const isBankCardPayment = payment_method?.value === 'bank_card_ca';
    const disableWireSubmit = (isBankCardPayment && !ENABLE_BANK_CARD) && (isCreditCardPayment && !ENABLE_CREDIT_CARD) && (!gateway || !payment_currency);
    const disableWalletSubmit = ENABLE_PAYMENT && !payment_method;

    useEffect(() => {
        if (tradingAccounts && paymentData) {
            setValue('toDeposit', formatAccountSelectOptions(tradingAccounts).find(item => item.value === paymentData?.trading_account_id))
        }
    }, [tradingAccounts]);

    const onSubmit = (data) => {
        if (ENABLE_CREDIT_CARD || ENABLE_BANK_CARD) {
            setIsSubmitting(true);
            addDeposit({
                amount: data?.amount,
                amount_type: data?.payment_currency?.value,
                trading_account_id: data?.toDeposit?.data?.trading_account,
                type: "deposit",
                method: data?.payment_method?.value,
            })
                .then(() => {
                    dispatch(addToast({
                        type: 'success',
                        message: intl.formatMessage({id: 'transaction.deposit.alert.created.cca'}),
                        timeout: 5000,
                    }));
                    setIsSubmitting(false);
                    closeModal();
                })
                .catch((err) => {
                    setIsSubmitting(false);
                    dispatch(addToast({
                        type: 'error',
                        message: getResponseErrorMessage(err),
                        timeOut: 5000,
                    }));
                })
        } else {
            navigate('/payment/form',
                {
                    state: {
                        amount: data?.amount,
                        trading_account_id: data?.toDeposit?.data?.trading_account,
                        payment_currency: data?.payment_currency?.value,
                        gateway: data?.gateway?.value,
                    },
                },
            );
            closeModal();
        }
    };

    const onOpenWallet = (data) => {
        dispatch(openModal({
            key: WALLET_MODAL_KEY,
            data: {
                amount: data?.amount,
                amount_type: data?.toDeposit?.data?.amount_type,
                trading_account_id: data?.toDeposit?.data?.trading_account,
                type: DEPOSIT_TYPE.DEPOSIT
            }
        }));
    };

    return (
        <div className="deposit-modal">
            <div className="modal-template-content-subtitle">
                {IS_DWS_DOMAIN ? (
                    <FormattedMessage
                        id="page.finance.deposit.popup.descr.dws.text"
                        defaultMessage="Your account manager will contact you to aid you in the activation process"
                    />
                ) : (
                    <FormattedMessage
                        id="page.finance.deposit.popup.descr.text"
                        defaultMessage="Request withdrawal from one of you trading accounts by selecting an account from the list, choosing a payment account and defining the desired withdrawal amount."
                    />
                )}
            </div>
            <form onSubmit={handleSubmit(isCreditCardPayment || isBankCardPayment ? onSubmit : onOpenWallet)}>
                {ENABLE_PAYMENT ? (
                    <SelectInput
                        id="payment_method_deposit_modal"
                        name="payment_method"
                        label={
                            <FormattedMessage
                                id="page.finance.widthdraw.popup.paymentMethod"
                                defaultMessage="Payment Method"
                            />
                        }
                        control={control}
                        rules={REQUIRED_FIELD_VALIDATION}
                        options={paymentsMethodDeposit}
                    />
                ) : null}
                {isCreditCardPayment && !ENABLE_CREDIT_CARD ?
                    <>
                        <SelectInput
                            id="gateway_deposit_modal"
                            options={paymentsGateways}
                            name="gateway"
                            label={intl.formatMessage({
                                id: "page.finance.deposit.popup.gateway",
                                defaultMessage: "Gateway"
                            })}
                            control={control}
                            rules={REQUIRED_FIELD_VALIDATION}
                        />
                        <SelectInput
                            id="payment_currency_deposit_modal"
                            options={gateway?.value === PAYMENTS.kasha ? paymentsCurrencyKasha : paymentsCurrency}
                            name="payment_currency"
                            label={intl.formatMessage({
                                id: "page.finance.deposit.popup.payment_currency",
                                defaultMessage: "Payment currency"
                            })}
                            control={control}
                            rules={REQUIRED_FIELD_VALIDATION}
                        />
                    </>
                    : null}
                <SelectInput
                    id="toDeposit_deposit_modal"
                    name="toDeposit"
                    label={
                        <FormattedMessage
                            id="page.finance.deposit.popup.field.chooseTradingAccount.title"
                            defaultMessage="Choose trading account"
                        />
                    }
                    control={control}
                    rules={REQUIRED_FIELD_VALIDATION}
                    maxMenuHeight={172}
                    options={formatAccountSelectOptions(tradingAccounts)}
                    formatOptionLabel={AccountSelectOption}
                    onChange={(data: { value: string, label: string, data: ITradingAcc }) => {
                        if (ENABLE_CREDIT_CARD && payment_currency?.value !== data.data.amount_type) {
                            setValue('payment_currency', {value: data.data.amount_type, label: data.data.amount_type});
                            setValue('amount', 0)
                        }
                    }}
                />
                <AmountInput
                    id="amount_deposit_modal"
                    name="amount"
                    label={
                        <FormattedMessage
                            id="page.finance.deposit.popup.field.amount.title"
                            defaultMessage="Amount"
                        />
                    }
                    control={control}
                    currency={payment_currency?.value}
                    rules={REQUIRED_FIELD_VALIDATION}
                    setValue={setValue}
                    buttons={DepositAmountOptions}
                />
                <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
                    <Button buttonType="outline" className="_m-r-15" onClick={closeModal}>
                        <FormattedMessage id="page.finance.deposit.popup.btn.cancel" defaultMessage="Cancel"/>
                    </Button>
                    <Button
                        tag="button"
                        type="submit"
                        isDisabled={!toDeposit || !amount || isSubmitting || disableWireSubmit || disableWalletSubmit}>
                        <FormattedMessage
                            id={isCreditCardPayment || isBankCardPayment ? "page.finance.withdraw.popup.btn.checkoutNow" : "page.finance.deposit.popup.btn.checkout"}
                            defaultMessage="Сheckout"
                        />
                    </Button>
                </div>
            </form>
        </div>
    )
}
