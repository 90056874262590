import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { currencyKashaPayments, getPaymentsInfo } from "../api/payments-currency";
import { RootState } from "./index";

interface IPayments {
    ENABLE_PAYMENT: number,
    ENABLE_CREDIT_CARD: number,
    ENABLE_CREDIT_CARD_WITHDRAW: number;
    ENABLE_BANK_CARD: number,
    ENABLE_BANK_CARD_WITHDRAW: number;
    ENABLE_WEB_TRADER: number | null,
    PAYMENT_CURRENCY: string[],
    PAYMENT_CURRENCY_KASHA: string[],
    PAYMENT_GATEWAYS: string[];
    TRANSLATE: Record<string, string>;
    // captcha
    ENABLE_RECAPTCHA: number;
    RECAPTCHA_KEY: string;

    WEBTRADER_URL: string | null;
}

const initialState: IPayments & { pending: boolean } = {
    pending: true,
    ENABLE_PAYMENT: 0,
    ENABLE_CREDIT_CARD: 0,
    ENABLE_CREDIT_CARD_WITHDRAW: 0,
    ENABLE_BANK_CARD: 0,
    ENABLE_BANK_CARD_WITHDRAW: 0,
    ENABLE_WEB_TRADER: null,
    PAYMENT_CURRENCY: [],
    PAYMENT_CURRENCY_KASHA: [],
    PAYMENT_GATEWAYS: [],
    TRANSLATE: {},
    // captcha
    ENABLE_RECAPTCHA: 0,
    RECAPTCHA_KEY: '',

    WEBTRADER_URL: null,
};

export const getPaymentsParameters = createAsyncThunk('payments/parameters/get', async (_, { rejectWithValue }) => {
        try {
            const data = await getPaymentsInfo();
            return {
                ENABLE_PAYMENT: Number(data.data.ENABLE_PAYMENT),
                ENABLE_CREDIT_CARD: Number(data.data.ENABLE_CREDIT_CARD),
                ENABLE_CREDIT_CARD_WITHDRAW: Number(data.data.ENABLE_CREDIT_CARD_WITHDRAW),
                ENABLE_BANK_CARD: Number(data.data.ENABLE_BANK_CARD),
                ENABLE_BANK_CARD_WITHDRAW: Number(data.data.ENABLE_BANK_CARD_WITHDRAW),
                ENABLE_WEB_TRADER: Number(data.data.ENABLE_WEB_TRADER),
                PAYMENT_CURRENCY: data.data.PAYMENT_CURRECY,
                PAYMENT_GATEWAYS: data.data.PAYMENT_GATEWAY,
                TRANSLATE: data.data.TRANSLATE,
                ENABLE_RECAPTCHA: Number(data.data.ENABLE_RECAPTCHA),
                RECAPTCHA_KEY: data.data.RECAPTCHA_KEY,
                WEBTRADER_URL: data.data.WEBTRADER_URL,
            }
        } catch (error: any) {
            return rejectWithValue(error.response.data.message)
        }
    }
);

export const getKashaPayments = createAsyncThunk('payments/kasha/get', async (_, { rejectWithValue }) => {
        try {
            const data = await currencyKashaPayments();
            return data.data.PAYMENT_CURRENCY
        } catch (error: any) {
            return rejectWithValue(error.response.data.message)
        }
    }
);

const paymentsCurrency = createSlice({
    name: 'payments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPaymentsParameters.fulfilled, (state, action) => {
            state.ENABLE_PAYMENT = action.payload.ENABLE_PAYMENT;
            state.ENABLE_CREDIT_CARD = action.payload.ENABLE_CREDIT_CARD;
            state.ENABLE_CREDIT_CARD_WITHDRAW = action.payload.ENABLE_CREDIT_CARD_WITHDRAW;
            state.ENABLE_BANK_CARD = action.payload.ENABLE_BANK_CARD;
            state.ENABLE_BANK_CARD_WITHDRAW = action.payload.ENABLE_BANK_CARD_WITHDRAW;
            state.PAYMENT_CURRENCY = action.payload.PAYMENT_CURRENCY;
            state.PAYMENT_GATEWAYS = action.payload.PAYMENT_GATEWAYS;
            state.ENABLE_WEB_TRADER = action.payload.ENABLE_WEB_TRADER;
            state.TRANSLATE = action.payload.TRANSLATE;
            // captcha
            state.ENABLE_RECAPTCHA = action.payload.ENABLE_RECAPTCHA;
            state.RECAPTCHA_KEY = action.payload.RECAPTCHA_KEY;

            state.WEBTRADER_URL = action.payload.WEBTRADER_URL;
            state.pending = false;
        });
        builder.addCase(getPaymentsParameters.rejected, (state) => {
            state.pending = false
        });
        builder.addCase(getKashaPayments.fulfilled, (state, action) => {
            state.PAYMENT_CURRENCY_KASHA = action.payload
        });
    }
});
const generateOptions = (data: string[]) => data.map(item => ({ value: item, label: item }));
const selectSelf = (state: RootState) => state.payments;
export const selectPaymentsPending = state => state.payments.pending;
export const selectPaymentsCurrency = createSelector(selectSelf, state => generateOptions(state.PAYMENT_CURRENCY));
export const selectPaymentsCurrencyKasha = createSelector(selectSelf, state => generateOptions(state.PAYMENT_CURRENCY_KASHA));
export const selectPaymentIsEnabled = createSelector(selectSelf, state => state.ENABLE_PAYMENT);
export const selectPaymentCreditCardEnabled = createSelector(selectSelf, state => state.ENABLE_CREDIT_CARD);
export const selectPaymentCreditCardWithdraw = (state: RootState) => state.payments.ENABLE_CREDIT_CARD_WITHDRAW;
export const selectPaymentBankCardEnabled = (state: RootState) => state.payments.ENABLE_BANK_CARD;
export const selectPeaymentBankCardWithdraw = (state: RootState) => state.payments.ENABLE_BANK_CARD_WITHDRAW;
export const selectPaymentsGateways = createSelector(selectSelf, state => generateOptions(state.PAYMENT_GATEWAYS));
export const selectPaymentsEnableWebTrader = createSelector(selectSelf, state => state.ENABLE_WEB_TRADER);
export const selectPaymentsTranslations = createSelector(selectSelf, state => state.TRANSLATE);
export const selectHasRecaptcha = (state: RootState) => state.payments.ENABLE_RECAPTCHA === 1;
export const selectRecaptchaKey = (state: RootState) => state.payments.RECAPTCHA_KEY;
export const selectWTUrl = (state: RootState) => state.payments.WEBTRADER_URL;

export const paymentsReducer = paymentsCurrency.reducer;
