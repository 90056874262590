import getUTCtime from '../../../utils/getUTCTime';
import CalendarPicker from "../../../components/Form/CalendarPicker/CalendarPicker";
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getMyInfo, updateMyInfo } from '../../../api/myProfile';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useGetLanguagesQuery } from "../../../store/languagesQuery";
import { useAppDispatch } from "../../../store";
import { addToast } from "../../../store/toast";
import { getResponseErrorMessage } from "../../../utils/getResponseErrorMessage";
import { Button } from "../../../components/Button/Button";
import { FormInput } from "../../../components/Form/FormInput/FormInput";
import { SelectInput } from "../../../components/Form/SelectInput/SelectInput";
import { PageNavigation } from "../../../components/PageNavigation/PageNavigation";
import { MyProfilePageTabs } from "../_shared/MyProfilePageTabs";
import { translateGenderOptions } from "../../../utils/translateGenderOtions";
import { LangOption } from "../../../types/Settings";
import './personal.scss';

const personalInfoDescr = "Keep your personal information updated! Keep in mind that we use this information in order to contact and inform you about your account at all times. Please make sure the information is accurate.";

const Personal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { handleSubmit, control, reset } = useForm({
        reValidateMode: 'onChange',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [formState, setFormState] = useState(null);
    const [id, setId] = useState(null);
    const {data: languages = []} = useGetLanguagesQuery([]);
    const genderOptions = translateGenderOptions(intl.formatMessage);

    usePageTitle(intl.formatMessage({
        id: "personalInfo.title",
        defaultMessage: "Personal info",
    }));

    const cancelAction = () => {
        reset({
            first_name: formState.profile.first_name === null ? '' : formState.profile.first_name,
            last_name: formState.profile.last_name === null ? '' : formState.profile.last_name,
            gender: {
                label: formState?.profile?.gender ? formState.profile.gender : null,
                value: formState?.profile?.gender ? formState.profile.gender : null,
            },
            birthday: formState.profile.birthday !== null ? formState.profile.birthday * 1000 : null,
            country: formState.country === null ? null : formState.country,
            language_id: formState.language === null ? null : formState.language,
            city: formState.profile.city === null ? '' : formState.profile.city,
            address: formState.profile.address === null ? '' : formState.profile.address,
            postal_code: formState.profile.postal_code === null ? '' : formState.profile.postal_code,
            email: formState.email === null ? '' : formState.email,
            alt_email: formState.profile.alt_email === null ? '' : formState.profile.alt_email,
            phone: formState.phone === null ? '' : formState.phone,
            alt_phone: formState.profile.alt_phone === null ? '' : formState.profile.alt_phone,
            country_specific_identifier:
                formState.profile.country_specific_identifier === null
                    ? ''
                    : formState.profile.country_specific_identifier,
            ident_number: formState?.profile?.ident_number ? formState?.profile?.ident_number : null,
        });
    };

    const fetchMyInfo = () => {
        getMyInfo().then((res) => {
            if (res) {
                const userData = res.data.data;
                setFormState(userData);
                setId(userData.profile.id)
                reset({
                    first_name: userData.profile.first_name,
                    last_name: userData.profile.last_name,
                    gender: userData.profile.gender ? {
                        label: userData.profile.gender,
                        value: userData.profile.gender
                    } : null,
                    birthday: userData.profile.birthday ? userData.profile.birthday * 1000 : null,
                    country: userData.country,
                    language_id: userData.language,
                    ident_number: userData.profile.ident_number,
                    city: userData.profile.city,
                    address: userData.profile.address,
                    postal_code: userData.profile.postal_code,
                    email: userData.email,
                    alt_email: userData.profile.alt_email ? userData.profile.alt_email : null,
                    phone: userData.phone,
                    alt_phone: userData.profile.alt_phone ? userData.profile.alt_phone : '',
                    country_specific_identifier: userData.profile.country_specific_identifier,
                });
            }
        });
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        const sendData = {
            id: id,
            first_name: data.first_name,
            last_name: data.last_name,
            gender: data.gender ? data.gender.value : null,
            birthday: data.birthday ? getUTCtime('startDay', data.birthday) / 1000 : null,
            country: data.country,
            language_id: data.language_id && data.language_id.id ? data.language_id.id : null,
            ident_number: data.ident_number,
            city: data.city,
            address: data.address,
            postal_code: data.postal_code,
            email: data.email,
            alt_email: data.alt_email,
            phone: data.phone,
            alt_phone: data.alt_phone,
            country_specific_identifier: data.country_specific_identifier,
        };
        updateMyInfo(sendData)
            .then((res) => {
                if (res.status === 200) {
                    fetchMyInfo();
                    dispatch(addToast({
                        type: 'success',
                        message: res.data?.message || intl.formatMessage({
                            id: 'status.success.title',
                            defaultMessage: 'Success'
                        }),
                        timeout: 3000,
                    }));
                }
            })
            .catch((error) => {
                if (error && error.response !== null) {
                    dispatch(addToast({
                        type: 'error',
                        message: getResponseErrorMessage(error),
                        timeout: 3000,
                    }))
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchMyInfo();
    }, []);

    return (
        <div className="personal-page _flex _flex-col">
            <h1><FormattedMessage id="personalInfo.title" defaultMessage="Personal info"/></h1>
            <PageNavigation nav={MyProfilePageTabs}>
                <Button buttonType="outline" onClick={() => cancelAction()} className="_m-r-15">
                    <FormattedMessage id="personalInfo.form.field.btn.reset" defaultMessage="Reset"/>
                </Button>
                <Button tag="button" disabled={isLoading} onClick={handleSubmit(onSubmit)}>
                    <FormattedMessage id="personalInfo.form.field.btn.update" defaultMessage="Update profile"/>
                </Button>
            </PageNavigation>
            <div className="row personal-page-description">
                <div className='_fz-14 _ff-roboto _lh-20 col c-lg-7 c-xs-12'>
                    <FormattedMessage id="personalInfo.descr" defaultMessage={personalInfoDescr}/>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="personal-page-form">
                <div className="_form-title">
                    <FormattedMessage id="personalInfo.form.title.main_info" defaultMessage="Main info"/>
                </div>
                <div className="row">
                    <div className="col c-md-6 c-lg-4">
                        <FormInput
                            id="first_name_id_personal"
                            name="first_name"
                            label={<FormattedMessage id="personalInfo.form.field.label.firstName"
                                                     defaultMessage="First name"/>}
                            control={control}
                            rules={{required: ' Field is required'}}
                            disabled={formState && formState.first_name !== null}
                        />
                    </div>
                    <div className="col c-md-6 c-lg-4">
                        <FormInput
                            id="last_name_id_personal"
                            name="last_name"
                            label={<FormattedMessage id="personalInfo.form.field.label.lastName"
                                                     defaultMessage="Last name"/>}
                            control={control}
                            rules={{required: ' Field is required'}}
                            disabled
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <SelectInput
                            id="gender_id_personal"
                            name="gender"
                            label={<FormattedMessage id="personalInfo.form.field.label.gender"
                                                     defaultMessage="Gender"/>}
                            control={control}
                            options={Object.values(genderOptions)}
                            formatOptionLabel={(option) => <div>{genderOptions?.[option.value]?.label}</div>}
                            menuPosition="absolute"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <CalendarPicker
                            id="birthday_id_personal"
                            name="birthday"
                            control={control}
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.birth"
                                    defaultMessage="Date of birth"
                                />
                            }
                            disabled={formState && formState.profile.birthday !== null}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <SelectInput
                            id="language_id"
                            name="language_id"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.language"
                                    defaultMessage="Language"
                                />
                            }
                            control={control}
                            options={languages.data}
                            getOptionValue={(opt: LangOption) => opt.id}
                            getOptionLabel={(opt: LangOption) => opt.name}
                            menuPosition="absolute"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id="ident_number_id"
                            name="ident_number"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.identNumber"
                                    defaultMessage="Identification Number"
                                />
                            }
                            control={control}
                        />
                    </div>
                </div>
                <hr className="_form-divider"/>
                <div className="_form-title">
                    <FormattedMessage id="personalInfo.form.title.address" defaultMessage="Address"/>
                    </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <SelectInput
                            id="country_id_personal"
                            name="country"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.country"
                                    defaultMessage="Country"
                                />
                            }
                            control={control}
                            options={languages.data}
                            getOptionValue={(opt: LangOption) => opt.id}
                            getOptionLabel={(opt: LangOption) => opt.name}
                            menuPosition="absolute"
                            isDisabled
                        />
                    </div>
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id="city_id_personal"
                            name="city"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.city"
                                    defaultMessage="City"
                                />
                            }
                            control={control}
                            disabled={formState && formState.profile.city !== null}
                        />
                    </div>
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id="address_id_personal"
                            name="address"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.address"
                                    defaultMessage="Address"
                                />
                            }
                            control={control}
                            disabled={formState && formState.profile.address !== null}
                        />
                    </div>
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id="postal_code_id"
                            name="postal_code"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.postalCode"
                                    defaultMessage="Postal code"
                                />
                            }
                            control={control}
                            disabled={formState && formState.profile.postal_code !== null}
                        />
                    </div>
                </div>
                <hr className="_form-divider"/>
                <div className="_form-title">
                    <FormattedMessage id="personalInfo.form.title.contact_info" defaultMessage="Contact info"/>
                </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id="email_id_personal"
                            name="email"
                            type="text"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.email"
                                    defaultMessage="E-mail"
                                />
                            }
                            control={control}
                            rules={{
                                pattern: {
                                    value:
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: (
                                        <FormattedMessage
                                            id="form.error.email"
                                            defaultMessage="Incorrect E-mail address"
                                        />
                                    ),
                                },
                            }}
                            disabled
                        />
                    </div>
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id="alt_email_id"
                            name="alt_email"
                            label={
                                <FormattedMessage
                                    id="personalInfo.form.field.label.altEmail"
                                    defaultMessage="Additional E-mail"
                                />
                            }
                            control={control}
                            rules={{
                                pattern: {
                                    value:
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: (
                                        <FormattedMessage
                                            id="form.error.email"
                                            defaultMessage="Incorrect E-mail address"
                                        />
                                    ),
                                },
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id='phone_id_personal'
                            name='phone'
                            label={<FormattedMessage id="personalInfo.form.field.label.phone" defaultMessage="Phone"/>}
                            control={control}
                            disabled
                        />
                    </div>
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id='alt_phone_id'
                            name='alt_phone'
                            label={<FormattedMessage
                                id="personalInfo.form.field.label.altPhone"
                                defaultMessage="Additional Phone"/>
                            }
                            maxLength='20'
                            control={control}
                            rules={{
                                validate: {
                                    correctPhone: (value) =>
                                        /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,12}$/.test(
                                            value) || !value || value.trim().length === 0 ||
                                            <FormattedMessage id="form.error.correctPhone" defaultMessage="Enter a different phone format"/>,
                                },
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col col c-md-6 c-lg-4">
                        <FormInput
                            id="country_specific_identifier"
                            name="country_specific_identifier"
                            label={<FormattedMessage
                                id="personalInfo.form.field.label.countryIdent"
                                defaultMessage="Country specific identifier"
                            />}
                            control={control}
                        />
                    </div>
                </div>
                <div className="mobile-buttons _flex _flex-j-e _m-t-30">
                    <Button buttonType="outline" onClick={cancelAction} className="_m-r-15">
                        <FormattedMessage id="personalInfo.form.field.btn.reset" defaultMessage="Reset"/>
                    </Button>
                    <Button tag='button' disabled={isLoading} onClick={handleSubmit(onSubmit)}>
                        <FormattedMessage id="personalInfo.form.field.btn.update" defaultMessage="Update profile"/>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Personal;
