const IS_DEV_MODE = process.env.REACT_APP_COMPANY_KEY === 'dev';
const ITS_WITH_ITALIAN = Boolean(Number(process.env.REACT_APP_ITALIANO));
const ITS_WITH_CZECH = Boolean(Number(process.env.REACT_APP_CZECH));
const ITS_WITH_JAPANESE = Boolean(Number(process.env.REACT_APP_JAPANESE));
const ITS_WITH_PORTUGUESE = Boolean(Number(process.env.REACT_APP_PORTUGUESE));
const ITS_WITH_TURKISH = Boolean(Number(process.env.REACT_APP_TURKISH));
const ITS_WITH_ROMANIAN = Boolean(Number(process.env.REACT_APP_ROMANIAN));
const ITS_WITH_DEUTSCH = Boolean(Number(process.env.REACT_APP_DEUTSCH));
const ITS_WITH_KOREAN = Boolean(Number(process.env.REACT_APP_KOREAN));
const IS_BLOCKED_IP_COUNTRY: Boolean = Boolean(Number(process.env.REACT_APP_BLOCKED_IP_COUNTRY));
const IS_DWS_DOMAIN: Boolean = Boolean(Number(process.env.REACT_APP_IS_DWS));

export {
  IS_DEV_MODE,
  ITS_WITH_ITALIAN,
  ITS_WITH_CZECH,
  ITS_WITH_JAPANESE,
  ITS_WITH_PORTUGUESE,
  ITS_WITH_TURKISH,
  ITS_WITH_ROMANIAN,
  ITS_WITH_DEUTSCH,
  ITS_WITH_KOREAN,
  IS_BLOCKED_IP_COUNTRY,
  IS_DWS_DOMAIN,
};
